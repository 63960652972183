<template>
 <div class="content">
   <div class="row">

     <div class="col-md-12">

  </div>
  <div class="col-md-12">

  </div>
</div>
 <div class="row">

      <div class="col-md-12">
        <vuestic-widget :headerText="'Detalle de Cliente Registrado'" :ButtonBack="true">
          <div class="row justify-content-md-center text-centered" style="text-align:center">

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <img class="card-img-top" style="width:200px" src="@/assets/images/check.png" alt="Card image cap">
                  <br>
                  <br>
                  <br>
                  <h2>Usuario Registrado</h2>
                  <h1>Nombre: {{name}}</h1>
                  <h2>Email: {{email}}</h2>
                  <h1>Tu Código: {{code}}</h1>
                </div>
                <div class="col-md-6">
                  <p>Puedes presentar el código <strong>{{code}}</strong> o mostrar el QR al llegar al evento</p>
                  <vue-qr :text=qrcode :correctLevel=3 :dotScale=1.0 :size=250></vue-qr>

                  <br>
                  <h2>Datos Adicionales</h2>
                  <p>Teléfono: {{phone}}</p>
                  <p>Dirección: {{address}}</p>
                  <p>Fecha Nacimiento: {{birthday}}</p>
                  <p>Edad: {{years_old}}</p>

                </div>
              </div>

            </div>

          </div>
        </vuestic-widget>

      </div>

    </div>
</div>
</template>

<script>
  import Vue from "vue";
  import VueQr from 'vue-qr'

  Vue.component('vue-qr', VueQr)
  import {
    SpringSpinner
  } from "epic-spinners";
  import {
    mapState,
    mapActions
  } from "vuex";
  export default {
    name: "registered",
    components: {
      SpringSpinner,
    },
    mounted() {

      var code_user = this.$route.params.code
      // console.log(id);
      if (code_user !== null) {
        this.loadCustomerByCode(code_user)
          .then(data => {
            if (data != null) {
              this.founded = true;
              this.name = data.name;

              this.phone = data.phone;
              this.address = data.address;
              this.birthday = data.birthday;
              this.years_old = data.years_old;


              this.code = data.code;
              this.email = data.email;
              this.qrcode = data.code;
            }


          })
          .catch(data => {
            this.addToastMessage({
              text: 'Ocurrio un error',
              type: 'warning'
            })
          })
      } else {
        this.addToastMessage({
          text: 'Parametro Incorrecto',
          type: 'warning'
        })
      }
    },
    computed: {
      ...mapState({}),
      isSuccessfulNameValid() {
        let isValid = false;
        if (this.formFields.name) {
          isValid = this.formFields.name.validated && this.formFields.name.valid;
        }
        return isValid;
      },
      isSuccessEmailValid() {
        let isValid = false;
        if (this.formFields.email) {
          isValid =
            this.formFields.email.validated && this.formFields.email.valid;
        }
        return isValid;
      }
    },
    data() {
      return {
        header: "Nuevo Cliente",
        typecustomeroptions: null,
        code: "",
        name_event: "",
        name: "",
        email: "",

        phone:"",
        address:"",
        birthday:"",
        years_old:"",

        founded: false,
        idevent: "",
        //Optional Inputs
        phone: "",
        address: "",
        gender: "",
        qrcode: "",
        genderoptions: [{
            name: "Mujer",
            id: 1
          },
          {
            name: "Hombre",
            id: 2
          },
          {
            name: "Otro",
            id: 3
          }
        ],

        birthday: "",
        //----
        idtypecustomer: "",
        active: 1,
        eventoptions: null
      };
    },
    methods: {
      ...mapActions([
        "loadCustomerByCode",
        "storeCustomer",
        "addToastMessage"
      ]),

      backPage: function () {
        this.$router.go(-1);
      },

    }
  };

</script>
<style lang="scss">

.color-icon-label-table {
  td:first-child {
    width: 1rem;
  }
}
</style>
